/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
    className: string
    data: any
    categories: any
    title: string
}

const ChartsWidgetSurvey: React.FC<Props> = ({className,data,categories,title}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = (data:any, categories: any) => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
      if (data != undefined && categories != undefined) {

          const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, categories))
          if (chart) {
              chart.render()
          }

          return chart
      }

  }

  useEffect(() => {
    const chart = refreshChart(data, categories)
    return () => {
      if (chart) {
        chart.destroy()
      }
      }

  }, [chartRef, mode, data, categories])

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'></span>
        </h3>

        {/* begin::Toolbar */}
        
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
              <div ref={chartRef} id={`kt_charts_widget_2_chart__${className}`} style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ChartsWidgetSurvey}

function getChartOptions(height: number, data: any, categories: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-warning')

  return {
    series: [
          {
          name: "",
        data: data,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
            borderRadius: 5,
            distributed: true
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
        labels: {
            show: false,
            formatter: function (val) {
                return val;
            },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        },
        decimalsInFloat: 0,
    },
    yaxis: {
        labels: {

            formatter: function (val) {
                return val.toFixed(0);
            },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
              return val.toFixed(0)+'';
        },
      },
      },
      colors: ["rgba(30, 67, 115, 0.2)", "rgba(30, 67, 115, 0.3)", "rgba(30, 67, 115, 0.35)", "rgba(30, 67, 115, 0.4)", "rgba(30, 67, 115, 0.5)", "rgba(30, 67, 115, 0.6)", "rgba(30, 67, 115, 0.7)", "rgba(30, 67, 115, 0.8)", "rgba(30, 67, 115, 0.9)", "rgba(30, 67, 115, 1)"],
      grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
